import React, {Fragment} from 'react';
import {graphql} from 'gatsby';

import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import HeroSectionStandalone from '@components/Hero';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {
  LatestSection,
  SubNavSection,
  StyledSection,
  OneImageWithContentSection,
  TwoImagesWithContentSection,
  CrosslinkSection,
  TestimonialSection,
  GetSmarterDetailSection,
  FullWidthImageSection,
} from '@components/sections';
import {CtaCardsGrid} from '@components/Card';
import {
  HeroSectionCallout,
  OutcomesSectionCallout,
} from '@components/SectionCallout';
import {detailPagesData} from '@components/Subnavigation/detailPagesData';
import {ThreeItemsOrganicGrid} from '@components/OrganicImageGrid';

import getDataFromBlockTypes from '@util/getDataFromBlockTypes';
import {ThemeProvider, styled, Colors} from '@styles';
import {Section} from '@components/sectioning';

const LatestStyledSection = styled(LatestSection).withConfig({
  componentId: 'edxLatest'
})`
  background: ${Colors.White};
`;

const StyledCrosslinkSection = styled(CrosslinkSection).withConfig({
  componentId: 'edxCrosslink'
})`
  background: ${Colors.FogLight};
`;

export default function edX({data}): JSX.Element {
  const {cms} = data;
  
  const {hero, body, searchDescription, seoTitle, parentPage, canonicalUrl} = cms.page;
  const {header: heroHeader, image: heroImage} = getDataFromBlockTypes(hero);
  const externalLinks = hero.filter(item => item.blockType == 'link_block');
  const {
    intro,
    partners,
    detail,
    shortcourse,
    testimonial,
    mediagrid,
    crossLink,
    ctaCards,
  } = getDataFromBlockTypes(body);
  
  return (

    <Fragment>
      <Meta title={seoTitle} description={searchDescription} />
      {canonicalUrl && <CanonicalUrl canonicalUrl={canonicalUrl} />}
      <ThemeProvider
        theme={{
          minPadding: '2em',
          bg: Colors.Transparent,
          fg: Colors.White,
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider theme={{maxWidth: '600px'}}>
            <HeroSectionStandalone heroSectionType={'short'} secondaryHero>
              <HeroSectionCallout heroProps={[heroHeader, externalLinks]} />
            </HeroSectionStandalone>
          </ThemeProvider>

          <SubNavSection
            filter={false}
            categories={detailPagesData}
            backLinkText={parentPage && parentPage.title}
            backLinkUrl={parentPage && parentPage.url}
          />

          {heroImage && heroImage.image && (
            <FullWidthImageSection
              {...heroImage}
              style={{maxHeight: '650px'}}
              imgStyle={{objectPosition: 'top center'}}
            />
          )}

        { intro && (<Section>
          <OutcomesSectionCallout {...intro} />
        </Section>)}
        
        { detail && <GetSmarterDetailSection {...detail} /> }
        { partners && (
          <ThemeProvider
            theme={{
              fg: Colors.Charcoal,
              bg: Colors.Transparent,
              ctaFg: Colors.Blue,
              ctaBg: Colors.Transparent,
              ctaHoverFg: Colors.White,
              ctaHoverBg: Colors.Blue,
            }}
          >
            <OneImageWithContentSection preventImageScale={true} {...partners} />
          </ThemeProvider>
        )}

        {/** Cta cards grid */}
        {ctaCards && (
          <ThemeProvider theme={{bg: Colors.White}}>
            <StyledSection noPadding>
              <ThemeProvider
                theme={{
                  bg: Colors.Blue,
                  fg: Colors.Charcoal,
                  ctaFg: Colors.White,
                  ctaIconFg: Colors.White,
                  ctaIconBg: Colors.White,
                  ctaHoverFg: Colors.Blue,
                  ctaHoverBg: Colors.White,
                }}
              >
                <CtaCardsGrid
                  ctacardsBlock={ctaCards}
                />
              </ThemeProvider>
            </StyledSection>
          </ThemeProvider>
        )}
        { mediagrid && (<ThemeProvider
            theme={{
              maxWidth: '80%',
              maxPadding: '4em 0 5em',
              minPadding: '2em',
            }}
          >
            <StyledSection>
              <ThreeItemsOrganicGrid {...mediagrid} />
            </StyledSection>
          </ThemeProvider>
        )}
            
        { testimonial && <TestimonialSection {...testimonial} /> }
        { shortcourse && <TwoImagesWithContentSection {...shortcourse} /> }
          <LatestStyledSection />
          <StyledCrosslinkSection {...crossLink} /> 
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment edXFragment on CMS_EdXPage {
    seoTitle
    searchDescription
    canonicalUrl
    parentPage {
      title
      url
    }
    hero {
      __typename
      ...headerBlockFragment
      ...pagelinkBlockFragment
      ...heroCmsImageFragment
      ...externalLinkBlockFragment
    }
    body {
      ...testimonialBlockFragment
      ...headerBodyFragment
      ...mediaGridFragment
      ...sectionBodyImageFragment
      ...sectionBodyTwoImagesFragment
      ...crosslinkBlockFragment
      ...ctacardsFragment
    }
  }
  query edX {
    cms {
      page(url: "/home/about/platform/") {
        ...edXFragment
      }
    }
  }
`;
